import React from "react";
import "./styles.css";


function HowItWorksB(){

    return(
        <div className="container" style={{alignItems:"flex-start"}}>   

        <div className="step-container list-container">
            <div className="list-title"> {"Tell us your basics"}</div>
            <ul className="list-content"> 
                <li> {"Your credit score range"} </li>
                <li> {"Set your monthly spends"} </li>
                <li> {"Benefits required"} </li>
            </ul>
        </div>

        <div className="step-container"> 
            <div className="number-class"> {"02"} </div>
            <img alt="matcher" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/matcher.png" />
            {/* <div className="img-container" style={{backgroundImage :  "url("+"https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/matcher.svg)"}} /> */}
        </div>

        <div className="step-container list-container">
            <div className="list-title"> {"Maximise your savings"}</div>
            <ul className="list-content"> 
                <li> {"Compare your top matches "} </li>
                <li> {"Apply directly on bank website"} </li>
                <li> {"Maximise your savings"} </li>
            </ul>

        </div>

    </div>
    );
}   

export default HowItWorksB;