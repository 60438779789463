import React, { Fragment, useEffect, useState } from "react";
import { userPersonas } from './utils';
import PersonaContainer from "./personaContainer";
import { fetchCardById } from '../../apis';
import "./styles/index.css";



function UserPersona(){

    const [allCardData, setAllCardData] = useState({});
  
    useEffect( () => {
        // const allCardsToFetch  = userPersonas.reduce( (acc, ele) => {
        //     acc = [...acc, ...ele.cardIds]
        //     return acc;
        // }, [] ).reduce( (acc, ele) => { 
        //     acc[ele] = ele;
        //     return acc;
        // }  , {} )
        // Promise.allSettled( Object.keys(allCardsToFetch).map( item => fetchCardById(item) )).then(
        //     response  => {
        //         let cardData = {}
        //         response[0].value.data.forEach(element => {
        //             cardData[element.key] = element
        //         });
        //         setAllCardData(cardData)
        //     }
        // ).catch(err => {
        //     console.log(err);
        // })
    
    }, [] )

    function scrollLeft(ev){
        ev.preventDefault();
        ev.stopPropagation();
        document.getElementsByClassName("user-persona-personas-container")[0].scrollLeft -= 200;

    }

    function scrollRight(ev){
        ev.preventDefault();
        ev.stopPropagation();
        document.getElementsByClassName("user-persona-personas-container")[0].scrollLeft += 200;
    }


  

    return (
    <div className="user-persona-container">
        {/* <div className="user-persona-title">   
             {"ARE YOU SOMEONE AMONG THESE ?"} 
         </div>   */}
         <div className="user-persona-title-main">  
            {"Meet our Top User Types!"}
         </div>
        <div id="user-persona-personas-container" className="user-persona-personas-container"> 
            <Fragment>
            { userPersonas.map(ele => <PersonaContainer 
                key={ele.key}
                id={ele.key}
                cardIds={ele.cardIds} 
                spendings={ele.spendings}
                heading={ele.heading}
                subheading={ele.subheading}
                imgUrl={ele.imgUrl}
                allCardData={allCardData}
                redirectUrl={ele.redirectUrl}
                cardSuggestTitle={ele.cardSuggestTitle}
                />)
            }       
            </Fragment>
        </div>
        <div className="show-more-persona show-left-persona" onClick={scrollLeft}> 
            <img  alt="left-arrow" style={{heigth: "100%", width: "65%"}} src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/left-arrow.svg" />  
        </div>
        <div className="show-more-persona show-right-persona" onClick={scrollRight}> 
            <img  alt="right-arrow" src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/right-arrow.svg" />  
        </div>
    </div>)
}


export default UserPersona;