import React from "react";
import "./styles.css";

function CategoryCard(props){
    const {categoryDetails = {}} = props;
    const { key, displayName, icon } = categoryDetails;

    return(
        <div key={key}  className="category-card-box">
            <img alt={displayName} className="category-icon-div" src={icon}/>
            <div className="category-display-text"> {displayName} </div>
        </div>
    )
}

export default CategoryCard;