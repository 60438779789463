import React, { useState } from "react";
import {faqsData} from './utils/faqs';
import "./styles/faqs.css";


function MmcFaqs(){

    const [  showFaq , setShowFaq  ] = useState(faqsData.reduce( (acc, ele) => { 
        acc[ele.question] = false;
        return acc;
    } , {})  );


    function changeShowFaq(ev, question){
        ev.preventDefault();
        setShowFaq({...showFaq , [question] : !showFaq[question]});
    }   

    return (
        <div style={{marginBottom : "5rem"}}>
            <div className="mmc-blogs-title"> {"Frequently asked questions"} </div>
            <div className="mmc-faqs-faq-container"> 

            {faqsData.map(ele => (<div className="faq-container">

                <div className="faq-question" onClick={ ev =>  changeShowFaq(ev,ele.question)}> 
                    <span> {ele.question}  </span>
                    <div >
                        <img alt="arrow-down" className={`faq-icon-down-${!showFaq[ele.question] ? "active" : "inactive"}`} src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-down-blue.svg"}/>
                        <img alt="arrow-up" className={`faq-icon-up-${showFaq[ele.question] ? "active" : "inactive"}`} src={"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-up-blue.svg"}/>
                    </div>
                </div>
                <div className={`faq-answer-${showFaq[ele.question] ? "active" : "inactive"}`} > 
                    {ele.answer} 
                </div>  

                </div>))}
            
              
            </div>
        </div>
    );
}

export default MmcFaqs;
