import React from "react";
import "./index.css";
import "../homepageUtils/styles/testimonials.css"


function PartnerBanks(props){
    const {logos = []} = props;
    const noLogos = ["citi-bank", "punjab-national-bank"]
    return(
        <div className="partner-bank">
            <div className="mmc-testimonials-title"> {"Cards from Leading Banks"} </div>
            <div className="partner-banks-logos-container"> 
                {logos.map(ele => noLogos.indexOf(ele.key) == -1 ?  <img alt={ele.key} className="partner-banks-logo" src={ele.logo}/> : null)}
            </div>
        </div>
    )
}

export default PartnerBanks;