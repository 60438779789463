export const userTestimonials = [
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/sid-jain.png",
        review : "Got my first Credit card from the recommendation and it's absolutely spot on with my requirements.",
        rating : "9/10",
        userName : "Siddharth Jain",
    },
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/balaji-sub.png",
        review : "Was confused about which card to get without a credit score. With MatchMyCard it took me minutes to decide.",
        userName : "Balaji Subramaniam",
        rating : "10/10",
    },
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/vaibhavi-singh.png",
        review : "Saved me from hours of research, and provided a very good recommendation based on my requirement.",
        rating : "9/10",
        userName : "Vaibhavi Singh",
    },

]