import React from "react";
import "./styles/persona.styles.css";
import "./styles/spends.slider.css";

function PersonaSpendings(props){
    const { spendings = [], id } = props;

    return (
        <div className="persona-spendings-container">
            {spendings.map(ele => <PersonaTypeSpend 
                id={id} data={ele} />)}
        </div>
    )
}

function PersonaTypeSpend(props){
    const {key, displayName,value,  icon, background, id} = props.data;

    return (
        <div className="persona-spends-spend-container">
            <div className="persona-spends-spend-container-sec-1">{displayName} </div>
            <div className="persona-spends-spend-container-sec-2 slider-logo-container"> 
                <input 
                    disabled
                    type="range" 
                    className="persona-spend-slider"
                    value={value}
                    style={{
                        backgroundImage:background,
                        marginLeft:"0",
                        backgroundSize : (value + "%") + " 100%"}}
                />  
                <div className="user-spend-type-icon-div">
                    <img alt="my-card-card-img" src={icon} style={{height: "1.25rem",
                            width: "1.25rem", "object-fit": "contain"}} 
                    className="my-card-card-img"/>
                </div> 

            </div>
            
        </div>
    )

}

export default PersonaSpendings;