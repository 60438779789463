import React from "react";
import { blogsData } from './utils/blogs';
import "./styles/blogs.css";

function MmcBlogs(){

    return (
        <div className="mmc-blogs-container">
            {/* <div className="mmc-blogs-info-title"> {"KNOW MORE ABOUT"} </div> */}
            <div className="mmc-blogs-title"> {"Credit Cards 101"} </div>
            <div className="mmc-blogs-blog-container">
                {blogsData.map( ele => (
                    <div className="mmc-blogs-blog"> 
                        <img src={ele.imgUrl} alt="blog_img" className="mmc-blogs-blog-img"/>
                        <div className="mmc-blogs-blog-title"> {ele.title} </div> 
                        <div className="mmc-blogs-blog-redirectText"> 
                            <span style={{marginRight : "0.3rem"}}> {ele.redirectText} </span>
                            <img src={ele.icon} alt="blog_img"/>   
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}

export default MmcBlogs;
