export const reasons = [
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/cashback-home.svg",
        text : "Get rewarded everytime you spend, Save upto 10%"
    },
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/credit-score.svg",
        text : "Get better loan offers with better CIBIL score."
    },
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/lounge-home.svg",
        text : "Enjoy complimentary perks, Live Luxuriously."
    },
]