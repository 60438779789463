import React from "react";
import "./styles/common.css";

function NoRejection(){
    return(
        <div className="no-rejection-container">
            <div className="no-rejection-title"> {"No more Rejections!"}  </div>
            <div className="no-rejection-value"> {"90"} <span style={{}}> {"%"} </span> </div>
            <div className="no-rejection-subtitle"> {"of the applications via SimplyFi are approved by Issuers."} </div>
        </div>
    )
}

export default NoRejection;