import React from "react";
import { userTestimonials } from './utils/testimonials';
import Rating from '../ratings';
import "./styles/testimonials.css";


function MmcTestimonials(){

    function scrollLeft(ev){
        ev.preventDefault();
        document.getElementsByClassName("mmc-testimonials-container")[0].scrollRight += 200 ;
    }

    return (
        <div className="mmc-testimonials-sec-container">
            <div className="mmc-testimonials-title"> {"Hear it from our users"} </div>
            <div className="mmc-testimonials-container"> 
                {userTestimonials.map( ele => (
                    <div className="mmc-testimonial">
                        <div className="mmc-testimonial-review"> {ele.review} </div>
                        <div className="mmc-testimonial-user-container"> 
                            <div className="mmc-testimonial-user-img-cont"> 
                                <img 
                                    alt="user_review" 
                                    style={{
                                    height:"100%" , width:"100%" , 
                                    objectFit : "contain"}} src={ele.imgUrl}/>
                            </div>
                            <div className="mmc-testimonial-user-rating"> 
                                <div> {ele.userName} </div>
                                <Rating rating={ele.rating}/>
                            </div> 
                        </div>
                    </div>
                ) )}
                <div className="testimonials-show-more" onClick={scrollLeft}> 
                    <img alt="right-arrow" src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/right-arrow.svg" onClick={scrollLeft}/>  
                </div>
            </div>
        </div>
    )
}

export default MmcTestimonials;
