import React from "react";
import "./styles.css";

function MobileView(){
    return(
        <div className="container" style={{}}>   

         
        <div className="step-container list-container">
            <div className="how-it-work-mobile-step-title">
                <div className="number-class"> {"01"} </div>
                <div className="list-title"> {"Tell us your basics"}</div>            
            </div>
            <div className="step-description">
                <img alt="transaction-book" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/transaction-book.png"/>
                {/* <div className="img-container" style={{backgroundImage :  "url("+")"}} /> */}
                <ul className="list-content"> 
                    <li> {"Your credit score range"} </li>
                    <li> {"Set your monthly spends"} </li>
                    <li> {"Required benefits"} </li>
                </ul>
            </div>
        </div>

        <div className="step-container list-container">
        <div className="how-it-work-mobile-step-title">
                <div className="number-class"> {"02"} </div>
                <div className="list-title"> {"Get the best card matches"}</div> 
            </div>
            <div className="step-description">
            <img alt="matcher" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/matcher.png"/>
            {/* <div className="img-container" style={{backgroundImage :  "url("+"https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/matcher.svg)"}} /> */}
                <ul className="list-content"> 
                    <li> {"We find cards you are eligible for"} </li>
                    <li> {"Savings calculated in 100+ cards"} </li>
                    <li> {"Best Matching Cards Suggested"} </li>
                </ul>
            </div>
            </div>

        <div className="step-container list-container">
        <div className="how-it-work-mobile-step-title">
                <div className="number-class"> {"03"} </div>
                <div className="list-title"> {"Maximise your savings"}</div>
            </div>
            <div className="step-description">
                <img alt="money-saving" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/money-saving.png"/>
                {/* <div className="img-container"   style={{backgroundImage :  "url("+"https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/money-saving.svg)"}}/> */}
                <ul className="list-content"> 
                    <li> {"Compare your top matches "} </li>
                    <li> {"Apply directly on bank website"} </li>
                    <li> {"Maximise your savings"} </li>
                </ul>
            </div>

        </div>

        </div>
    )
}

export default MobileView;