import React from "react";
import "./styles/common.css";

import { reasons } from './constants';

function ReasonsToGet(){
    return(
        <div className="reason-to-get-container" style={{background: "#fff"}}>
            <div className="reason-to-get-title"> {"Why should you get one?"} </div>
            <div className="reasons-list">
                {reasons.map( ele => (
                    <div className="reason-cont">
                        <img alt={ele.text} src={ele.icon} style={{marginRight : "1rem"}}/>
                        <div> {ele.text} </div> 
                    </div>
                ) )}
                
            </div>
        </div>
    )
}

export default ReasonsToGet;