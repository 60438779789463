export const blogsData  = [
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/improve-credit-score.png",
        title : "Bad Credit score? Boost it now.",
        redirectUrl : "",
        redirectText : "Know more",
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-right-solid.png"
    },
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/credit-card-no-income.png",
        title : "No Income? You can still get a credit card",
        redirectUrl : "",
        redirectText : "Know more",
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-right-solid.png"
    },
    {
        imgUrl : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/save-big.png",
        title : "Save BIG using your credit cards.",
        redirectUrl : "",
        redirectText : "Know more",
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-right-solid.png"
    },
]