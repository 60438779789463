import React, { Fragment } from "react";
import "./styles.css";

function Stepper(props){
    const { count = 3 } = props;
    const rangeCount = [...Array(count).keys()];
    return(
        <div className="stepper-container">
            {rangeCount.map( (ele, ind) => {
                return(
                    <Fragment>
                        <div className="tag-parent">
                            <div className="tag" /> 
                        </div>
                        { ind < rangeCount.length -1 ? <div className="dotted-line" />: "" } 
                    </Fragment>
                )
            })}
        </div>
    )
}

export default Stepper;