import * as React from "react"
import { graphql , Link } from 'gatsby';
import Layout from '../components/layout/index';
import Stepper from "../components/stepper";
import CategoryCard from "../components/categoryCard";
import HowItWorksA from "../components/howitworks/HowItWorksA";
import HowItWorksB from "../components/howitworks/HowItWorksB";
import MobileView from "../components/howitworks/MobileView";
import MmcBlogs from "../components/homepageUtils/MmcBlogs";
import MmcFaqs from "../components/homepageUtils/MmcFaqs";
import MmcTestimonials from "../components/homepageUtils/MmcTestimonials";
import NoRejection from "../components/homepageUtils/NoRejection";
import ReasonsToGet from "../components/homepageUtils/ReasonsToGet";

import UserPersona from '../components/userPersona';
import PartnerBanks from "../components/partnerBanks";
import "./styles/homepage-styles.css";

import lottie from 'lottie-web';

import {topCategoriesList} from '../utilities/constants'; 


const IndexPage = ({data}) => {
  const {allMongodbMmcnewBankdatas} = data;
  const logos = allMongodbMmcnewBankdatas.nodes;
  const bannerImgRef = React.useRef(null);


  React.useEffect( () => {
    let anim = lottie.loadAnimation({
      container: bannerImgRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: 'https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/simplyfi-banner-image-lottie.json',
    });

    anim.addEventListener("DOMLoaded",() => {
      document.getElementById("mmc-banner-img-static").style.display = "none";
    })

    return () => {
      anim.removeEventListener("DOMLoaded");
      anim.destroy()
    }; // Clean up on component unmount

  } , []);

  return (
    <Layout> 
      <div className="head-section"> 
        <div className="head-section-text">
          <div className="header-text">
            <div className="header-text-title"> {"Save up to 10% on all your spends!"}</div>
            <div className="header-section-sub-title"> {"Don’t settle for any credit card."} <br/>{"Find the right one."}</div>
          </div>
            <Link to={"/find-my-match"}> 
              <div className="try-now"> {"Find my card"} </div>                
            </Link>
        
        </div>
          <img  alt="brand-img-static" id="mmc-banner-img-static"  className="mmc-banner-img" src="https://credzyimages.s3.ap-south-1.amazonaws.com/logo/brand-img-static.svg" />
          <div ref={bannerImgRef} className="mmc-banner-img"  id="mmc-banner-img"></div>
          {/* <img className="mmc-banner-img" src="https://credzyimages.s3.ap-south-1.amazonaws.com/logo/piggy-bank.png" /> */}
      </div>
      <div className="try-now-mobile-div">
        <Link className="try-now-mobile" to={"/find-my-match"}> 
              <div> {"Find my card"} </div>                
        </Link>
      </div>
{/* 
      <div className="show-desktop-view" 
        style={{display : "flex" , justifyContent : "space-around", textAlign : "center"}}>
        <ReasonsToGet />
        <NoRejection />
      </div> */}


      <div className="show-mobile-view"> <ReasonsToGet /> </div>
      
      <div className="body-section">
        {/* <div className="help-text"> {"With more than 100 options available it can be daunting to make the right credit card. Let's find the perfect pick for you."} </div> */}
        <div>
        {/* style={{ background: "linear-gradient(180deg, #F5FBFF 3.82%, rgba(245, 251, 255, 0.23) 144.16%)"}} */}
          <div className="how-it-works-title"> {"How It Works"}  </div>
          <div className="how-it-works">
              <HowItWorksA />
              <Stepper />
              <HowItWorksB />
          </div>
          <div className="how-it-works-mobile"> <MobileView />  </div>
        </div>  
        
        <div className="show-mobile-view"> <NoRejection /> </div>

        <div className="category-banner-section">
          <div className="category-banner-title">  {"Explore Top Credit Cards Categories"} </div>
          <div className="categories-container">
              {topCategoriesList.map( (cardCategory) => (
                <Link to={cardCategory.url} > 
                  <CategoryCard categoryDetails={cardCategory} /> 
                  </Link>) 
              )}
          </div>
        </div>

        <UserPersona />

        <MmcBlogs />

        <PartnerBanks logos={logos}/>

        <MmcTestimonials />
        <MmcFaqs />
        
      </div>
    </Layout>
  )
} 

export default IndexPage;

export const query = graphql`
query{
  allMongodbMmcnewBankdatas {
    nodes {
      logo
      key
    }
  }
}`
export const Head = () => (
  <React.Fragment>
  <title>SimplyFi: India’s First AI based Credit Card Recommendation Platform</title>
  <meta name="description" content="Welcome to SimplyFi, the ultimate platform for simplifying your credit card selection process. With hundreds of credit card options available in the market, it can be overwhelming to choose the right one that suits your needs. That's where SimplyFi comes in."/>

  <meta property="og:url" content="https://joinsimplyfi.com/" />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="SimplyFi: Save up to 10% on all your spends!" />
  <meta property="og:description" content="Welcome to SimplyFi, the ultimate platform for simplifying your credit card selection process. With hundreds of credit card options available in the market, it can be overwhelming to choose the right one that suits your needs. That's where SimplyFi comes in." />
  <meta property="og:image" content="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/brand-img-static.jpg" />
  <meta property="og:image:secure" content="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/brand-img-static.jpg" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta property="twitter:domain" content="joinsimplyfi.com" />
  <meta property="twitter:url" content="https://joinsimplyfi.com/" />
  <meta name="twitter:title" content="SimplyFi: Save up to 10% on all your spends!" />
  <meta name="twitter:description" content="Welcome to SimplyFi, the ultimate platform for simplifying your credit card selection process. With hundreds of credit card options available in the market, it can be overwhelming to choose the right one that suits your needs. That's where SimplyFi comes in." />
  <meta name="twitter:image" content="https://credzyimages.s3.ap-south-1.amazonaws.com/logo/brand-img-static.svg" />

  </React.Fragment>);

