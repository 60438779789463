export const userPersonas = [
    {   
        key:"credit-newbie",
        heading : "Credit Newbie",
        imgUrl: "https://credzyimages.s3.ap-south-1.amazonaws.com/personas/credit-newbie.png",
        redirectUrl : "/card-category/secured",
        // subheading : "You have just started your job and looking to ",
        // cardSuggestTitle : "If you are just like our Miss Shopper, here are some cards for you with best rewards and offers.",
        spendings : [
            {
                key:"online-spends",
                displayName : "Online Spends",
                value: "50",
                icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/e-commerce.svg",
                background: "linear-gradient(90.85deg, #3D8BFF 9.8%, #657FDD 55.09%, #3D8BFF 108.6%)"
            },{
                key : "lifestyle",
                displayName : "Lifestyle",
                value: "20",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/fuel.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #003B85 45.96%, #1C3994 95.43%)"
            },{
                key : "other",
                displayName : "Other Spends",
                value: "30",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/other-spends.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #0C815A 95.43%)"
            }
        ],
        cardIds : ["axis-flipkart-credit-card","axis-flipkart-credit-card"]
    },
    {
        key:"the-millennial",
        heading : "The Millennials",
        imgUrl: "https://credzyimages.s3.ap-south-1.amazonaws.com/personas/the-millennial.png",
        redirectUrl : "/card-category/best-credit-cards",
        // subheading : "A person who is an avid traveller and/or frequest flyer this is best card for them",
        // cardSuggestTitle : "If you are just like our Miss Shopper, here are some cards for you with best rewards and offers.",
        spendings : [
            {
                key:"online-spends",
                displayName : "Online Spends",
                value: "50",
                icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/travel.svg",
                background: "linear-gradient(90.85deg, #3D8BFF 9.8%, #657FDD 55.09%, #3D8BFF 108.6%)"
            },{
                key : "lifestyle",
                displayName : "Lifestyle",
                value: "30",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/e-commerce.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #003B85 45.96%, #1C3994 95.43%)"
            },{
                key : "travel",
                displayName : "Travel",
                value : "20",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/other-spends.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #0C815A 95.43%)"
            }
        ],
        cardIds : ["axis-flipkart-credit-card","axis-flipkart-credit-card"]
    },
    {
        key:"the-family-man",
        heading : "The Family Man",
        imgUrl: "https://credzyimages.s3.ap-south-1.amazonaws.com/personas/the-family-man.png",
        redirectUrl : "/card-category/premium",
        // subheading : "A person who spent most of the money on food ordering and fine dining this is best card for them",
        // cardSuggestTitle : "If you are just like our Miss Shopper, here are some cards for you with best rewards and offers.",
        spendings : [
            {
                key : "lifestyle",
                displayName : "Lifestyle",
                value: "40",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/e-commerce.svg",
                background: "linear-gradient(90.85deg, #3D8BFF 9.8%, #657FDD 55.09%, #3D8BFF 108.6%)"
            },{
                key:"bills-and-insurance",
                displayName : "Bills & Insurance",
                value: "30",
                icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/food-ordering.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #003B85 45.96%, #1C3994 95.43%)"
                
            },{
                key : "travel",
                displayName : "Travel",
                value : "30",
                icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/other-spends.svg",
                background: "linear-gradient(84.05deg, #168AEB 0.44%, #0C815A 95.43%)"
            }
        ],
        cardIds : ["axis-flipkart-credit-card","axis-flipkart-credit-card"]
    },
    // {
    //     key:"tech-nomad",
    //     heading : "Tech Nomad",
    //     redirectUrl : "/card-category/travel",
    //     imgUrl: "https://www.freepnglogos.com/uploads/shopping-bag-png/shopping-bag-plaseto-bag-plaseto-bags-manufacturer-west-bengal-17.png",
    //     // subheading : "A person who spent most of the money on food ordering and fine dining this is best card for them",
    //     // cardSuggestTitle : "If you are just like our Miss Shopper, here are some cards for you with best rewards and offers.",
    //     spendings : [
    //         {
    //             key : "online-spends",
    //             displayName : "Online Spends",
    //             value: "30",
    //             icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/e-commerce.svg",
    //             background: "linear-gradient(90.85deg, #3D8BFF 9.8%, #657FDD 55.09%, #3D8BFF 108.6%)"
    //         },{
    //             key:"travel",
    //             displayName : "Travel",
    //             value: "45",
    //             icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/food-ordering.svg",
    //             background: "linear-gradient(84.05deg, #168AEB 0.44%, #003B85 45.96%, #1C3994 95.43%)"
                
    //         },{
    //             key : "other",
    //             displayName : "Other",
    //             value : "25",
    //             icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/other-spends.svg",
    //             background: "linear-gradient(84.05deg, #168AEB 0.44%, #0C815A 95.43%)"
    //         }
    //     ],
    //     cardIds : ["axis-flipkart-credit-card","axis-flipkart-credit-card"]
    // }
]