export const faqsData = [
    {
        question : "Why do I need credit card recommendations?",
        answer : "Finding a credit card that fits a person's spending habits can be very difficult and time-consuming given that there are more than 100 credit cards available." + 
        " We provide you with the finest options by using our algorithm to match your spending to the best cards on the market.",
    },
    {
        question : "Does it affect my credit score?",
        answer : "No, Your credit score is unaffected because we obtain it directly from you rather than pulling it from Credit Bureaus."
         + "We remove that parameter from the algorithm if you are unaware of your credit score.",
    },
    {
        question : "Are there any charges for it?",
        answer : "No, we don't charge our users for it." +
        "Instead, we give them an affiliate link to apply for a credit card, this doesn't affect their application and it helps us keep our services running.",
    },
]