import React from "react";
import CardMetaBody from "../cardMetaData/body";
import PersonaSpendings from "./personaSpendings";
import CardPreviewPersona from './cardPreviewPersona';
import "./styles/persona.styles.css";
import "../homepageUtils/styles/blogs.css";
import { Link } from "gatsby";


function PersonaContainer(props){
    const {cardIds , spendings, heading , subheading, id, allCardData = {}, imgUrl,
     cardSuggestTitle, redirectUrl} = props;
    return (<div key={id + "container"} className="persona-type-container">

        <div className="persona-title">
            <img alt={heading} src={imgUrl} className="persona-type-img"/>
            {heading}
        </div>

        <div className="persona-type-section">

            <div className="persona-type-section-sec-1">
                <div className="persona-user-desc"> {subheading} </div>
                <PersonaSpendings
                    key={id + "persona-spending"} 
                    spendings={spendings}    
                />
            </div>
            {/* <div className="persona-type-section-sec-1">
                
            </div> */}
            <Link to={redirectUrl}>
                <div className="mmc-blogs-blog-redirectText" style={{padding: "0rem 1rem"}}> 
                    <span style={{marginRight : "0.3rem"}}> {"See relevant cards"} </span>
                    <img  alt="arrow-right-solid" src="https://credzyimages.s3.ap-south-1.amazonaws.com/icons/arrow-right-solid.png"/> 
                </div>
            </Link>
        </div>
{/* 
        <div className="persona-best-cards-title"> {cardSuggestTitle} </div>

        <div className="persona-best-cards-container">
            {cardIds.map(ele => <CardPreviewPersona cardData={allCardData[ele]} />)}
        </div> */}


    </div>)
}

export default PersonaContainer;