import React from "react";
import "./styles.css";

function HowItWorksA(){
    return(
        <div className="container">   

            <div className="step-container"> 
                <div className="number-class"> {"01"} </div>
                <img alt="transaction-book" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/transaction-book.png" />
                {/* <div className="img-container" style={{backgroundImage :  "url("+"https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/transaction-book.svg)"}} /> */}
            </div>

            <div className="step-container list-container">
                <div className="list-title"> {"Get the best card matches"}</div>
                <ul className="list-content"> 
                    <li> {"We find cards you are eligible for"} </li>
                    <li> {"Savings calculated in 100+ cards"} </li>
                    <li> {"Best matching cards suggested"} </li>
                </ul>

            </div>

            <div className="step-container">
                <div className="number-class"> {"03"} </div>
                <img alt="money-saving" className="img-container" src="https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/money-saving.png" />
                {/* <div className="img-container"   style={{backgroundImage :  "url("+")"}}/> */}
            </div>

        </div>
    )
}

export default HowItWorksA;